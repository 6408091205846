<template>
   <div v-if="pagination.links" class="paginator-container">
      <nav>
         <ul class="pagination" style="margin-bottom:0px; margin-top:24px;">
            <li class="page-item">
               <a class="page-link" href="javascript:;" @click="goToFirstPage" aria-label="Previous">
               <span aria-hidden="true">&laquo;</span>
               <span class="sr-only"></span>
               </a>
            </li>
            <li class="page-item">
               <a class="page-link" href="javascript:;" @click="goToPrevPage" aria-label="Previous">
               <span aria-hidden="true">&lsaquo;</span>
               <span class="sr-only"></span>
               </a>
            </li>
            <li class="page-item" v-for="(link,k) in pagination.links.filter(o => { return !o.includes('Next') && !o.includes('Previous') })" :key="k" :class="{'active' : $route.query.page !== undefined ? $route.query.page === link : false}">
               <a class="page-link" href="javascript:;" @click="goToPage(link)" >{{link}}</a>
            </li>
            <li class="page-item">
               <a class="page-link" href="javascript:;" @click="goToNextPage" aria-label="Next">
               <span aria-hidden="true">&rsaquo;</span>
               <span class="sr-only"></span>
               </a>
            </li>
            <li class="page-item">
               <a class="page-link" href="javascript:;" @click="goToLastPage" aria-label="Next">
               <span aria-hidden="true">&raquo;</span>
               <span class="sr-only"></span>
               </a>
            </li>
         </ul>
      </nav>
   </div>
</template>
<script>
export default {
   props : {
      latest: { required:false, default: false }
   },
   computed: {
      pagination() {
         return this.$root.pagination
      }
   },
   methods: {
      goToPage(page) {
         if (page === '...') { return ; }
         this.$root.goToPage(page, this.latest)
      },
      goToFirstPage() {
         if (Number(this.$route.query.page) !== 1) {
            this.goToPage(1);
         }
      },
      goToPrevPage() {
         if (Number(this.$route.query.page) > 1) {
            this.goToPage(Number(this.$route.query.page)-1);
         }
      },
      goToNextPage() {
         if (Number(this.$route.query.page) < Number(this.$root.pagination.lastPage)) {
            this.goToPage(Number(this.$route.query.page)+1);
         }
      },
      goToLastPage() {
         if (Number(this.$route.query.page) < Number(this.$root.pagination.lastPage)) {
            this.goToPage(this.$root.pagination.lastPage);
         }
      }
   }
}
</script>