
<template>
   <div class="container" style="max-width:700px;">
      <div class="table" v-if="order !== undefined">
         <table class="table table-borderless table-sm">
            <tr>
               <td style="width: 30%">{{ $trans('createdAt', $root.user.locale) }}</td>
               <td v-text="moment(order.created_at).format('DD/MM/YYYY HH:mm')">
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('userName', $root.user.locale) }}</td>
               <td v-text="order.user.companyname">
               </td>
            </tr>
            <tr>
               <td style="width: 30%">email</td>
               <td v-text="order.user.email"></td>
            </tr>
            <tr>
               <td colspan="2">
                  <table class="table table-borderless table-sm">
                     <tr><th></th><th>{{ $trans('product', $root.user.locale) }}</th><th  style="text-align:right">Qty</th></tr>
                     <tr v-for="(item, k) in order.items" :key="k">
                        <td style="width:54px;"><img :src="item.product.all_icons[0]" style="max-height:50px; max-width:50px;"/></td>
                        <td><a :href="'/products/'+item.product_id" target="_blank" v-text="item.product.name+' ['+item.product.erp_id+']'"></a></td>
                        <td v-text="item.qty" style="text-align:right"></td>
                     </tr>
                     <tr><td colspan="3" style="text-align:right" v-text="'________'"></td></tr>
                     <tr><td colspan="3" style="text-align:right" v-text="'tot '+order.items_count"></td></tr>
                  </table>
               </td>
            </tr>
         </table>
         <button v-show="$root.user.admin" class="btn btn-danger" style="float:right;" @click="deleteOrder">{{ $trans('deleteOrder') }}</button>
      </div>
   </div>
</template>
<script>
export default {
   mounted() {
      this.getData()
   },
   data() {
      return {
         order: undefined,
      }
   },
   methods: {
      getData() {
         window.axios.get('/orders/'+this.$route.params.id, {
            headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
         })
         .then(res => {
            this.order = res.data
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      },
      deleteOrder() {
         if (confirm(this.$trans('deleteOrder_confirm', this.$root.user.locale))) {
            window.axios.post('/orders/'+this.$route.params.id+'/delete', 
               {},
               {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
            .then(() => {
               this.$router.push('/admin-orders');
            })
            .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });});  
         }
      }
   }
}
</script>
