<template>
   <div>
      <div v-if="products.length" class="card-deck">
         <div class="container">
            <topFilters :latest="latest" :sortingEnabled="sortingEnabled"></topFilters>
            <paginator :latest="latest"></paginator>
            <div class="row">
               <div :id="product.id" class="col-md-3 col-sm-6" v-for="product in products" :key="product.id">
                  <div class="product-grid2">
                     <router-link tag="div" :to="'/products/'+product.id" class="product-image2">
                        <div class="pic-1" v-if="product.all_icons[0] !== undefined" :style="'background-image:url('+product.all_icons[0]+')'">
                        </div>
                        <div class="pic-2" v-if="product.all_icons[1] !== undefined" :style="'background-image:url('+product.all_icons[1]+')'">
                        </div>
                        <div v-if="product.status == 'available'" class="add-to-cart" @click.prevent="addToCart(product.id)">{{ $trans('addToCart') }}</div>
                        <div v-else class="add-to-cart" style="bottom:0px; cursor:default; background-color:#a55353;" @click.prevent.self>{{ $trans('unavailable') }}</div>
                     </router-link>
                     <router-link tag="div" :to="'/products/'+product.id" class="product-content">
                        <h3 class="title" v-text="product.name"></h3>
                        <div class="price" v-text="getSubTitle(product)"></div>
                     </router-link>
                  </div>
               </div>
            </div>
            <paginator :latest="latest"></paginator>
         </div>
      </div>
      <div v-else class="card-deck px-3">
         {{ $trans('noProducts') }}. <router-link to="/search">{{ $trans('backToSearch') }}</router-link>
      </div>
   </div>
</template>
<script>
import Paginator from './Paginator'
import TopFilters from './TopFilters'
export default {
   props : {
      latest: { required: false, default: false },
      sortingEnabled: { required: false, default: true }
   },
   components : {paginator: Paginator, topFilters: TopFilters},
   computed: {
      products() {
         return this.$root.products;
      }
   },
   methods: {
      getSubTitle(product) {
         let arr = [];
         if (product.erp_id) { arr.push(product.erp_id) }
         if (product.formatted_attributes['length'] && product.formatted_attributes['width']) { 
            arr.push(product.formatted_attributes['length']+'x'+product.formatted_attributes['width']+' cm') 
         }
         if (product.formatted_attributes['design']) { 
            arr.push(product.formatted_attributes['design']) 
         }
         return arr.join(', ')
      },
      addToCart(id) {
         this.$root.addToCart(id)
      }
   }
}
</script>