<template>
   <div class="container" style="max-width:700px;">
      <div class="table">
         <table class="table table-borderless table-sm">
            <tr>
               <td style="width: 30%">{{ $trans('email', $root.user.locale) }}*</td>
               <td>
                  <div class="form-group">
                     <input type="email" v-model="user.email" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">Password*</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.password" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr><td colspan="2"><br></td></tr>
            <tr>
               <td style="width: 30%">{{ $trans('userName', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.name" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('companyname', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.companyname" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('telephone', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.tel" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('fax', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.fax" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('mobile', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.mobile" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('website', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.website" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('country', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <select v-model="user.countryCode" class="form-control">
                        <option v-for="(country,code) in countries" :key="code" :value="code" v-text="country" />
                     </select>
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('postcode', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.postcode" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('address', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.address" class="form-control" />
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('city', $root.user.locale) }}</td>
               <td>
                  <div class="form-group">
                     <input type="text" v-model="user.city" class="form-control" />
                  </div>
               </td>
            </tr>
         </table>      
         <br>
         <button class="btn btn-success" @click="save">{{ $trans('save', $root.user.locale) }}</button>
      </div>

   </div>
</template>
<script>
import countries from './../../countryCodes.js'
export default {
   beforeMount() {
      if (this.$root.user.id === undefined) {
         this.$router.push('/');
      }
   },
   mounted() {
   },
   data() {
      return {
         user: {},
         countries : countries,
      }
   },
   methods: {
      save() {
         window.axios.post('/users/new', 
            this.user,
            {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
         .then(res => {
            window.$.notify({ message: this.$trans('alert_objectCreated', this.$root.user.locale) }, { type: 'success', delay: 2000 });
            this.$router.push('/admin-users/'+res.data.id)
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      },
   }
}
</script>