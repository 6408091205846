<template>
   <div>
      <div class="container" v-if="!$root.loading">
         <div class="row" v-show="this.$root.products.length > 1">
            <div class="col-md-6">
               <button class="btn btn-sm btn-outline-primary" @click="prevProduct"><i class="fas fa-angle-left"></i> {{ $trans('previous') }}</button>
            </div>
            <div class="col-md-6" style="text-align:right">
               <button class="btn btn-sm btn-outline-primary" @click="nextProduct">{{ $trans('next') }} <i class="fas fa-angle-right"></i></button>
            </div>
            <br>
            <br>
         </div>
         <div class="card">
            <div class="row">
               <aside class="col-sm-7 border-right">
                  <article class="gallery-wrap" style="position:relative"> 
                     <div @click="prevImage" style="float:left; font-size:50px;padding-top:30%; height:66%;position:absolute; left:20px;width:40px;cursor:pointer; z-index:9;">
                        <i class="fa fa-angle-left" style="background-color: white; padding: 10px; opacity: 0.8;"></i>
                     </div>
                     <div @click="nextImage" style="float:right; font-size:50px;padding-top:30%;height:66%;position:absolute; right:20px;width:40px;cursor:pointer; z-index:9;">
                        <i class="fa fa-angle-right" style="background-color: white; padding: 10px; opacity: 0.8;"></i>
                     </div>
                     <div class="img-big-wrap" style="position:relative">
                        <div class="img-big-wrap-container"> 
                           <a :href="currentPicture.replace('/small','')" data-lightbox="images"><img :src="currentPicture.replace('/small','')"></a>
                        </div>
                     </div>
                     <div class="img-small-wrap">
                        <div class="item-gallery" v-for="(picture,k) in product.all_icons" :key="k">
                           <a v-if="picture !== currentPicture" :href="picture.replace('/small','')" data-lightbox="images" style="display:none">x</a>
                           <img :src="picture.replace('/small','')" @click="currentPicture = picture">
                        </div>
                     </div>
                  </article>
               </aside>
               <aside class="col-sm-5">
                  <article class="card-body p-5">
                     <h3 class="title mb-3" v-text="product.name"></h3>
                     <!-- only admin -->
                     <div v-show="$root.user.admin" >
                        <button class="btn btn-warning" @click="$router.push('/admin-products/'+product.id)">Admin</button>
                     </div>
                     <!-- END only admin -->
                     <!-- <h6 class="title mb-3" v-text="getSubTitle"></h6> -->
                     <br>
                     <dl class="item-property" v-if="product.description">
                        <dd><p v-text="product-description"></p></dd>
                     </dl>
                     <div class="table" v-if="product.formatted_attributes">
                        <table class="table">
                           <tr v-if="product.erp_id" style="background-color: #e9e9e9">
                              <td style="width:40%">{{ $trans('ID') }}</td>
                              <td><b>{{ product.erp_id }}</b></td>
                           </tr>
                           <tr v-if="product.formatted_attributes['origin']">
                              <td style="width:40%">
                                 {{ $trans('origin') }}
                              </td>
                              <td>{{ product.formatted_attributes.origin }}</td>
                           </tr>
                           <tr v-if="product.formatted_attributes['length']">
                              <td style="width:40%">{{ $trans('length') }}</td>
                              <td>{{ product.formatted_attributes.length }}</td>
                           </tr>
                           <tr v-if="product.formatted_attributes['width']">
                              <td style="width:40%">{{ $trans('width') }}</td>
                              <td>{{ product.formatted_attributes.width }}</td>
                           </tr>
                           <tr v-if="product.formatted_attributes['qm']">
                              <td style="width:40%">{{ $trans('square_footage') }}</td>
                              <td>{{ product.formatted_attributes.qm }}</td>
                           </tr>
                           <tr v-if="product.formatted_attributes['color'].length > 0">
                              <td style="width:40%">{{ $trans('color') }}</td>
                              <td>{{ product.formatted_attributes.color.join(', ') }}</td>
                           </tr>
                           <tr v-if="product.formatted_attributes['color2'].length > 0">
                              <td style="width:40%">{{ $trans('color2') }}</td>
                              <td>{{ product.formatted_attributes.color2.join(', ') }}</td>
                           </tr>
                           <tr v-if="product.formatted_attributes['design']">
                              <td style="width:40%">{{ $trans('print') }}</td>
                              <td>{{ product.formatted_attributes.design }}</td>
                           </tr>
                           <tr v-if="product.formatted_attributes['material']">
                              <td style="width:40%">{{ $trans('material') }}</td>
                              <td>{{ product.formatted_attributes.material }}</td>
                           </tr>
                           <tr v-if="product.knots">
                              <td style="width:40%">{{ $trans('knots') }}</td>
                              <td>{{ product.knots }}</td>
                           </tr>
                           <!-- only admin -->
                           <tr v-if="product.price_per_qm" v-show="$root.user.admin">
                              <td style="width:40%">{{ $trans('price_per_qm') }}</td>
                              <td>{{ product.price_per_qm }}</td>
                           </tr>
                           <tr v-if="product.price_per_unit" v-show="$root.user.admin">
                              <td style="width:40%">{{ $trans('price_per_unit') }}</td>
                              <td>{{ product.price_per_unit }}</td>
                           </tr>
                           <!-- end only admin -->
                        </table>
                     </div>
                     <a v-if="product.status == 'available'" href="javascript:;" @click="addToCart" class="btn btn-lg btn-outline-primary text-uppercase"> <i class="fas fa-shopping-cart"></i> Add to cart </a>
                     <div v-else style="background-color:#a55353; color:white; width: 100%; height: 40px; text-align:center; padding:7px;"> {{ $trans('unavailable') }} </div>
                  </article>
               </aside>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   mounted() {
      this.fetchData()
   },
   data() {
      return {
         product: {},
         currentPicture: ''
      }
   },
   computed: {
      getSubTitle() {
         if (!this.product.formatted_attributes) { return ''; }
         let arr = [];
         if (this.product.formatted_attributes['origin']) { arr.push(this.product.formatted_attributes['origin']) }
         if (this.product.formatted_attributes['design']) { arr.push(this.product.formatted_attributes['design']) }
         return arr.join(' - ')
      },
   },
   methods: {
      prevImage() {
         let currentIndex = this.product.all_icons.indexOf(this.currentPicture);
         if (currentIndex == 0) {
            this.currentPicture = this.product.all_icons[this.product.all_icons.length -1];
         } else {
            this.currentPicture = this.product.all_icons[currentIndex -1] 
         }
      },
      nextImage() {
         let currentIndex = this.product.all_icons.indexOf(this.currentPicture);
         if (currentIndex == (this.product.all_icons.length -1)) { 
            this.currentPicture = this.product.all_icons[0] 
         }
         else {
            this.currentPicture = this.product.all_icons[currentIndex +1] 
         }
      },
      fetchData() {
         this.$root.loading = true;
         this.$root.latestProductId = this.$route.params.id;
         window.axios.get('/products/'+this.$route.params.id, 
         {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
         .then(res => {
            this.product = res.data;
            this.product.all_icons = [...new Set(this.product.all_icons)]
            this.currentPicture = res.data.all_icons[0]
            this.$root.loading = false;
         })
         .catch(err => {
            if (err.message.includes("404")) {
               window.$.notify({ message: this.$trans('product_notfound') }, { type: 'danger', delay: 2000 });
               this.$router.push('/list');
            } else {
               window.$.notify({ message: err }, { type: 'danger', delay: 2000 });
            }
         })
      },
      addToCart() {
         this.$root.addToCart(this.product.id)
      },
      prevProduct() {
         this.$root.loading = true;
         if (this.$root.products.length) {
            let obj = this.$root.products.find(x => x.id === this.product.id);
            let newIndex = this.$root.products.indexOf(obj)-1;
            if (newIndex === -1) { newIndex = this.$root.products.length -1 }
            this.$router.push('/products/'+this.$root.products[newIndex].id);
         } else {
            window.axios.get('/products/'+this.$route.params.id+'/prev', 
               {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
               .then(res => {
                  this.$router.push('/products/'+res.data.id);
                  // this.product = res.data;
                  // this.product.all_icons = [...new Set(this.product.all_icons)]
                  // this.currentPicture = res.data.all_icons[0]
                  // this.$root.loading = false;
               })
         }
      },
      nextProduct() {
         this.$root.loading = true;
         if (this.$root.products.length) {
            let obj = this.$root.products.find(x => x.id === this.product.id);
            let newIndex = this.$root.products.indexOf(obj)+1;
            if (newIndex === this.$root.products.length) { newIndex = 0 }
            this.$router.push('/products/'+this.$root.products[newIndex].id);
         } else {
            window.axios.get('/products/'+this.$route.params.id+'/next', 
               {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
               .then(res => {
                  this.$router.push('/products/'+res.data.id);
                  // this.product = res.data;
                  // this.product.all_icons = [...new Set(this.product.all_icons)]
                  // this.currentPicture = res.data.all_icons[0]
                  // this.$root.loading = false;
               })
         }
      }
   }
}
</script>

