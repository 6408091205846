<template>
   <div>
   </div>
</template>
<script>
export default {
   mounted() {
      if (this.$root.checkAuth()) {
         this.$router.push('/search')
      }
   },
   data() {
      return {

      }
   }
}
</script>
