<template>
   <div class="container-fluid">
      <div class="row justify-content-center">
         <div class="col-md-6">
            <div class="form-group">
               <label>ERP ID:</label>
               <input type="text" class="form-control" v-model="product.erp_id" />
            </div>
            <div class="form-group">
               <label>{{ $trans('name', $root.user.locale) }}</label>
               <input type="text" class="form-control" v-model="product.name" />
            </div>
            <div class="form-group">
               <label>{{ $trans('availability', $root.user.locale) }}</label>
               <select class="form-control" v-model="product.status">
                  <option value="available">{{ $trans('available', $root.user.locale) }}</option>
                  <option value="unavailable">{{ $trans('unavailable', $root.user.locale) }}</option>
               </select>
            </div>
            <div class="form-group">
               <label> {{ $trans('origin', $root.user.locale) }}</label>
               <input type="text" class="form-control" v-model="product.formatted_attributes.origin"  />
            </div>
            <div class="form-group">
               <label> {{ $trans('design', $root.user.locale) }}</label>
               <input type="text" class="form-control" v-model="product.formatted_attributes.design"  />
            </div>
            <div class="form-group">
               <label> {{ $trans('color', $root.user.locale) }}</label>
               <multiselect 
                  :value="product.formatted_attributes.color.map(o => { return typeof o === 'object' ? o : { value: o }})" 
                  :options="termOptions.color.map(o => { return { value: o }})" 
                  :multiple="true" 
                  :close-on-select="true" 
                  :clear-on-select="false" 
                  :preserve-search="true" 
                  placeholder="Pick some" 
                  label="value"
                  key-by="value"
                  @select="selectMultiple($event, 'color')"
                  @remove="removeMultiple($event, 'color')"
                  :preselect-first="false">
               </multiselect>
            </div>
            <div class="form-group">
               <label> {{ $trans('color2', $root.user.locale) }}</label>
               <multiselect 
                  :value="product.formatted_attributes.color2.map(o => { return typeof o === 'object' ? o : { value: o }})" 
                  :options="termOptions.color2.map(o => { return { value: o }})" 
                  :multiple="true" 
                  :close-on-select="true" 
                  :clear-on-select="false" 
                  :preserve-search="true" 
                  placeholder="Pick some" 
                  label="value"
                  key-by="value"
                  @select="selectMultiple($event, 'color2')"
                  @remove="removeMultiple($event, 'color2')"
                  :preselect-first="false">
               </multiselect>
            </div>
            <div class="form-group">
               <label> {{ $trans('width', $root.user.locale) }}</label>
               <input type="number" class="form-control" v-model="product.formatted_attributes.width"  />
            </div>
            <div class="form-group">
               <label> {{ $trans('length', $root.user.locale) }}</label>
               <input type="number" class="form-control" v-model="product.formatted_attributes.length"  />
            </div>
            <div class="form-group">
               <label> {{ $trans('square_footage', $root.user.locale) }}</label>
               <input type="number" class="form-control" v-model="product.formatted_attributes.qm"  />
            </div>
            <div class="form-group">
               <label> {{ $trans('material', $root.user.locale) }}</label>
               <input type="text" class="form-control" v-model="product.formatted_attributes.material"  />
            </div>
            <div class="form-group">
               <label> {{ $trans('price_per_unit', $root.user.locale) }}</label>
               <input type="number" class="form-control" v-model="product.formatted_attributes.price_per_unit"  />
            </div>
            <div class="form-group">
               <label> {{ $trans('price_per_qm', $root.user.locale) }}</label>
               <input type="number" class="form-control" v-model="product.formatted_attributes.price_per_qm"  />
            </div>
            <br>
            <button class="btn btn-success" @click="save">{{ $trans('save', $root.user.locale) }}</button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-warning" @click="refreshIcons">{{ $trans('refreshIcons', $root.user.locale) }}</button>
            <br>
            <hr>
            <br>
            <h5>API</h5>
            <hr>
            <div class="table">
               <table class="table">
                  <tr v-for="user in product.users" :key="user.id">
                     <td>{{ user.erp_id }}</td>
                     <td>{{ user.companyname }}</td>
                     <td>{{ user.name }}</td>
                     <td>{{ user.email }}</td>
                     <td><i class="fa fa-times" title="Remove" @click="removeFromApiUser(user.id)" style="cursor:pointer"></i></td>
                  </tr>
               </table>
            </div>
            <select class="form-control" @change="addNewApiUser($event)" ref="addNewApiUser" style="padding-top:4px">
               <option value="" selected disabled>{{ $trans('addToUser', $root.user.locale) }} ..</option>
               <option v-for="user in apiUsers" :key="user.id" v-text="user.id_name" :value="user.id"></option>
            </select>
         </div>
         <div class="col-md-6">
            <article class="gallery-wrap" style="position:relative"> 
               <div @click="prevImage" style="float:left; font-size:50px;padding-top:30%; height:66%;position:absolute; left:20px;width:40px;cursor:pointer; z-index:9;">
                  <i class="fa fa-angle-left" style="background-color: white; padding: 10px; opacity: 0.8;"></i>
               </div>
               <div @click="nextImage" style="float:right; font-size:50px;padding-top:30%;height:66%;position:absolute; right:20px;width:40px;cursor:pointer; z-index:9;">
                  <i class="fa fa-angle-right" style="background-color: white; padding: 10px; opacity: 0.8;"></i>
               </div>
               <div class="img-big-wrap" style="position:relative">
                  <div class="img-big-wrap-container"> 
                     <a :href="currentPicture.replace('/small','')" data-lightbox="images"><img :src="currentPicture.replace('/small','')"></a>
                  </div>
               </div>
               <div class="img-small-wrap">
                  <div class="item-gallery" v-for="(picture,k) in product.all_icons" :key="k">
                     <a v-if="picture !== currentPicture" :href="picture.replace('/small','')" data-lightbox="images" style="display:none">x</a>
                     <img :src="picture.replace('/small','')" @click="currentPicture = picture">
                  </div>
               </div>
               <br />
               <hr />
               <button class="btn btn-danger" style="float:right;" @click="deleteProduct">{{ $trans('deleteProduct') }}</button>
            </article>
         </div>
      </div>
   </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
   components: { Multiselect },
   mounted() {
      window.axios.get('/products/'+this.$route.params.id+'/admin', 
         {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
      .then(res => {
         this.product = res.data.product;
         this.apiUsers = res.data.apiUsers;
         this.currentPicture = res.data.product.all_icons[0]
         this.termOptions = res.data.termOptions
      })
      .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
   },
   data() {
      return {
         product: {
            formatted_attributes: {
               color: [],
               color2: []
            }
         },
         apiUsers: [],
         currentPicture: '',
         termOptions: {color: [], color2: []}
      }
   },
   methods: {
      save() {
         window.axios.post('/products/'+this.$route.params.id, 
            this.product,
            {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
         .then(res => {
            this.product = res.data
            this.product.formatted_attributes = res.data.formatted_attributes
            window.$.notify({ message: this.$trans('alert_objectUpdated', this.$root.user.locale) }, { type: 'success', delay: 2000 });
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });});         
      },
      refreshIcons() {
         window.axios.post('/products/'+this.$route.params.id+'/refresh-icons', 
            {},
            {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
         .then(res => {
            window.$.notify({ message: this.$trans('alert_objectUpdated', this.$root.user.locale) }, { type: 'success', delay: 2000 });
            this.product = res.data
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });}); 
      },
      prevImage() {
         let currentIndex = this.product.all_icons.indexOf(this.currentPicture);
         if (currentIndex == 0) {
            this.currentPicture = this.product.all_icons[this.product.all_icons.length -1];
         } else {
            this.currentPicture = this.product.all_icons[currentIndex -1] 
         }
      },
      nextImage() {
         let currentIndex = this.product.all_icons.indexOf(this.currentPicture);
         if (currentIndex == (this.product.all_icons.length -1)) { 
            this.currentPicture = this.product.all_icons[0] 
         }
         else {
            this.currentPicture = this.product.all_icons[currentIndex +1] 
         }
      },
      addNewApiUser(event) {
         window.axios.post('/products/'+this.$route.params.id+'/add-to-api/'+event.target.value, 
            this.product,
            {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
         .then(res => {
            this.product.users = res.data
            this.$refs['addNewApiUser'].value = "";
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });});       
      },
      removeFromApiUser(userId) {
         window.axios.post('/products/'+this.$route.params.id+'/remove-from-api/'+userId, 
            this.product,
            {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
         .then(res => {
            this.product.users = res.data
            this.$refs['addNewApiUser'].value = "";
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });});       
      },
      deleteProduct() {
         if (confirm(this.$trans('deleteProduct_confirm', this.$root.user.locale))) {
            window.axios.post('/products/'+this.$route.params.id+'/delete', 
               {},
               {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
            .then(() => {
               this.$router.push('/admin-products');
            })
            .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });});  
         }
      },
      selectMultiple(value, attribute) {
         this.product.formatted_attributes[attribute].push(value.value)
      },
      removeMultiple(value, attribute) {
         if (this.product.formatted_attributes[attribute].indexOf(value.value) > -1) {
            this.product.formatted_attributes[attribute].splice(this.product.formatted_attributes[attribute].indexOf(value.value), 1)
         }
      }
   }
}
</script>