const strings = {
    registry: {
        en: 'Registry',
        it: 'Anagrafica',
        de: 'Registrierung',
        fr: 'Enregistrement',
    },
    integration: {
        en: 'Integration',
        it: 'Integrazione',
        de: 'Integration',
        fr: 'Intégration',
    },
    email: {
        en: 'Email',
        it: 'Email',
        de: 'Email',
        fr: 'Email',
    },
    password: {
        en: 'Password',
        it: 'Password',
        de: 'Passwort',
        fr: 'Mot de passe',
    },
    login: {
        en: 'Log In',
        it: 'Accedi',
        de: 'Anmeldung',
        fr: 'Connexion',
    },
    logout: {
        en: 'Log Out',
        it: 'Scollegati',
        de: 'Abmelden',
        fr: 'Déconnexion',
    },
    search: {
        en: 'Search',
        it: 'Cerca',
        de: 'Suche',
        fr: 'Recherchez',
    },
    list: {
        en: 'Products list',
        it: 'Elenco prodotti',
        de: 'Produkte Liste',
        fr: 'Liste des produits',
    },
    latest: {
        en: 'Latest products',
        it: 'Nuovi arrivi',
        de: 'Neuheiten',
        fr: 'Nouveaux produits',
    },
    cart: {
        en: 'Cart',
        it: 'Carrello',
        de: 'Warenkorb',
        fr: 'Panier',
    },
    personalArea: {
        en: 'Personal area',
        it: 'Area Personale',
        de: 'Personalbereich',
        fr: 'Zone personnelle',
    },
    addToCart: {
        en: 'Add to cart',
        it: 'Aggiungi al carello',
        de: 'In den Warenkorb',
        fr: 'Ajouter au panier',
    },
    clearCart: {
        en: 'Clear cart',
        it: 'Svuota il carrello',
        de: 'Leeren Sie Ihren Einkaufswagen',
        fr: 'Videz votre panier',
    },
    removeFromCart: {
        en: 'Remove from cart',
        it: 'Rimuovi dal carello',
        de: 'Artikel entfernen',
        fr: 'Retirer article',
    },
    remove: {
        en: 'Remove',
        it: 'Rimuovi',
        de: 'Entfernen',
        fr: 'Retirer',
    },
    length: {
        en: 'Length',
        it: 'Lunghezza',
        de: 'Länge',
        fr: 'Longueur',
    },
    width: {
        en: 'Width',
        it: 'Larghezza',
        de: 'Breite',
        fr: 'Largeur',
    },
    surface: {
        en: 'Surface',
        it: 'Superficie',
        de: 'Oberfläche',
        fr: 'Surface',
    },
    from: {
        en: 'From',
        it: 'Da',
        de: 'Von',
        fr: 'De',
    },
    to: {
        en: 'To',
        it: 'A',
        de: 'Bis',
        fr: 'À',
    },
    defaultCategories: {
        en: 'Standard categories',
        it: 'Categorie standard',
        de: 'Standardkategorien',
        fr: 'catégories standard',
    },
    placeOrder: {
        en: 'Place order',
        it: 'Effettua ordine',
        de: 'Bestellung aufgeben',
        fr: 'Passer la commande'
    },
    compileToChange: {
        en: 'Fill in to change',
        it: 'Compila per modificare',
        de: 'Zum ändern ausfüllen',
        fr: 'Remplir pour changer'
    },
    filters: {
        en: 'Filters',
        it: 'Filtri',
        de: 'Filter',
        fr: 'Filtres',
    },
    filtersEnabled: {
        en: 'Filters enabled',
        it: 'Filtri abilitati',
        de: 'Filter aktiviert',
        fr: 'Filtres activés',
    },
    update: {
        en: 'Update',
        it: 'Aggiorna',
        de: 'Aktualisierung',
        fr: 'Mise à jour',
    },
    removeLogo: {
        en: 'Remove logo',
        it: 'Rimuovi logo',
        de: 'Logo entfernen',
        fr: 'Supprimer le logo',
    },
    newLogo: {
        en: 'New logo',
        it: 'Nuovo logo',
        de: 'Neues Logo',
        fr: 'Nouveau logo',
    },
    color: {
        en: 'Background Color',
        it: 'Colore Fondo',
        de: 'Hauptfarbe',
        fr: 'Couleur de l\'arrière',
    },
    color2: {
        en: 'Border Color',
        it: 'Colore Bordo',
        de: 'Nebenfarbe',
        fr: 'Couleur de la bordure',
    },
    knots: {
        en: 'Knots',
        it: 'Nodi',
        de: 'Knoten',
        fr: 'Noeuds',
    },
    material: {
        en: 'Material',
        it: 'Materiale',
        de: 'Material',
        fr: 'Matériel',
    },
    origin: {
        en: 'Country',
        it: 'Paese',
        de: 'Land',
        fr: 'Pays',
    },
    enableAllProducts: {
        en: 'Enable ALL products',
        it: 'Abilita TUTTI i prodotti',
        de: 'ALLE Produkte aktivieren',
        fr: 'Activer TOUS les produits',
    },
    productsInCart: {
        en: 'You have [x] products in your cart',
        it: 'Hai [x] prodotti nel carrello',
        de: 'Sie haben [x] Produkte in Ihrem Warenkorb',
        fr: 'Vous avez [x] produits dans votre panier',
    },
    noProducts: {
        en: 'No products',
        it: 'Nessun prodotto',
        de: 'Kein Produkt',
        fr: 'Pas de produit',
    },
    backToSearch: {
        en: 'Back to search',
        it: 'Torna alla ricerca',
        de: 'Zurück zur Suche',
        fr: 'Retour à la recherche',
    },
    square_footage: {
        en: 'Square footage',
        it: 'Area in m²',
        de: 'Fläche in m²',
        fr: 'Surface en m²',
    },
    prices: {
        en: 'Price',
        it: 'Prezzo',
        de: 'Preis',
        fr: 'Prix',
    },
    price_per_unit: {
        en: 'Price per unit',
        it: 'Prezzo /pz',
        de: 'Preis/Stück',
        fr: 'Prix / pcs',
    },
    price_per_qm: {
        en: 'Price per qm',
        it: 'Prezzo al m²',
        de: 'Preis per m²',
        fr: 'Prix par m²',
    },
    sendPrices: {
        en: 'Send prices',
        it: 'Invia prezzi',
        de: 'Preise senden',
        fr: 'Prix par m²',
    },
    design: { // in db: title
        en: 'Design/Pattern',
        it: 'Design/Pattern',
        de: 'Design/Muster',
        fr: 'Design/Pattern',
    },
    refreshIcons: {
        en: 'Refresh Icons',
        it: 'Rigenera icone',
        de: 'Symbole regenerieren',
        fr: 'Régénérer les icônes',
    },
    passwordLost: {
        en: 'Password lost?',
        it: 'Password persa?',
        de: 'Passwort vergessen?',
        fr: 'Mot de passe perdu?'
    },
    sendEmail: {
        en: 'Send email',
        it: 'Invia email',
        de: 'EMail senden',
        fr: 'Envoyer un email'
    },
    alert_passwordSent: {
        en: 'Password sent on email',
        it: 'Password inviata via email',
        de: 'Passwort per E-Mail gesendet',
        fr: 'Mot de passe envoyé par email'
    },
    alert_wrongAddress: {
        en: 'Wrong address',
        it: 'Indirizzo errato',
        de: 'Falsche Adresse',
        fr: 'Mauvaise adresse'
    },
    alert_addedToApi: {
        en: 'Products added to API',
        it: 'Prodotti aggiunti alle API',
        de: 'Produkte zu den API hinzugefügt',
        fr: 'produits ajoutés aux API'
    },
    availability: {
        en: 'Availability',
        it: 'Disponibilità',
        de: 'Verfügbarkeit',
        fr: 'Disponibilité',
    },
    available: {
        en: 'Available',
        it: 'Disponibile',
        de: 'Erhältlich',
        fr: 'Disponible',
    },
    unavailable: {
        en: 'Not Available',
        it: 'Non Disponibile',
        de: 'Nicht Verfügbar',
        fr: 'Pas Disponible',
    },
    alert_productsNotAvailable: {
        en: 'One or more products are not available. Remove them from cart to proceed.',
        it: 'Uno o più prodotti non sono disponibili. Rimuovili dal carrello per procedere.',
        de: 'Ein oder mehrere Produkte sind nicht verfügbar. Entfernen Sie sie aus dem Einkaufswagen, um fortzufahren.',
        fr: 'Un ou plusieurs produits ne sont pas disponibles. Retirez-les du panier pour continuer.',
    },
    print: { // in db: title
        en: 'Model',
        it: 'Modello',
        de: 'Modell',
        fr: 'Modèle',
    },
    firstName: {
        en: 'Name',
        it: 'Nome',
        de: 'Name',
        fr: 'Nom',
    },
    userName: {
        en: 'Name of user',
        it: 'Nome utente',
        de: 'Name des Benutzers',
        fr: 'Nom de usager',
    },
    companyname: {
        en: 'Companyname',
        it: 'Rag. Sociale',
        de: 'Firmenname',
        fr: 'Nom de la société',
    },
    telephone: {
        en: 'Tel',
        it: 'Tel.',
        de: 'Tel.',
        fr: 'Tél.',
    },
    fax: {
        en: 'Fax',
        it: 'Fax',
        de: 'Fax',
        fr: 'Fax',
    },
    mobile: {
        en: 'Celphone',
        it: 'Tel. Cellulare',
        de: 'Handy',
        fr: 'Mobile',
    },
    website: {
        en: 'Website',
        it: 'Sito web',
        de: 'Website',
        fr: 'Site web',
    },
    country: {
        en: 'Country',
        it: 'Paese',
        de: 'Land',
        fr: 'Pays',
    },
    postcode: {
        en: 'Zipcode',
        it: 'CAP',
        de: 'Postgeschichte',
        fr: 'Code postal',
    },
    address: {
        en: 'Address',
        it: 'Indirizzo',
        de: 'Adresse',
        fr: 'Adresse',
    },
    city: {
        en: 'City',
        it: 'Città',
        de: 'Stadt',
        fr: 'Ville',
    },
    save: {
        en: 'Save',
        it: 'Salva',
        de: 'Speichern',
        fr: 'Sauver',
    },
    orderBy: {
        en: 'Sort by',
        it: 'Ordina per',
        de: 'Sortieren nach',
        fr: 'Trier par',
    },
    perPage: {
        en: 'Per page',
        it: 'Per pagina', // cerca di mantenere minuscole e maiuscole come scritte
        de: 'pro Seite',   // solo questo deve essere minuscolo
        fr: 'Par page',
    },
    asc: {
        en: 'asc',
        it: 'asc', // ascendente (ordine)
        de: 'asc',
        fr: 'asc',
    },
    desc: {
        en: 'desc',
        it: 'desc', // discendente (ordine)
        de: 'desc',
        fr: 'desc',
    },
    new: {
        en: 'New',
        it: 'Nuovo', // discendente (ordine)
        de: 'New',
        fr: 'New',
    },
    product: {
        en: 'Product',
        it: 'Prodotto', // cerca di mantenere minuscole e maiuscole come scritte
        de: 'Product',
        fr: 'Product',
    },
    name: { // nome prodotto
        en: 'Name',
        it: 'Nome', // cerca di mantenere minuscole e maiuscole come scritte
        de: 'Name',
        fr: 'Nom',
    },
    createdAt: {
        en: 'Insert Date',
        it: 'Data di inserimento',
        de: 'Datum der Eingabe',
        fr: 'Date de la saisie',
    },
    groups: {
        en: 'Groups',
        it: 'Gruppi',
        de: 'Gruppen',
        fr: 'Groupes',
    },
    ID: {
        en: 'Product ID',
        it: 'Identificativo',
        de: 'Artikelnummer',
        fr: 'Numéro article',
    },
    users: {
        en: 'Users',
        it: 'Utenti',
        de: 'Benutzer',
        fr: 'utilisateurs',
    },
    products: {
        en: 'Products',
        it: 'Prodotti',
        de: 'Produkte',
        fr: 'Des produits',
    },
    orders: {
        en: 'Orders',
        it: 'Ordini',
        de: 'Aufträge',
        fr: 'Ordres',
    },
    productsShown: {
        en: 'Products shown',
        it: 'Prodotti mostrati',
        de: 'Produkte zeigten',
        fr: 'Produits affichés',
    },
    previous: {
        en: 'Previous',
        it: 'Precedente',
        de: 'Vorherige',
        fr: 'Précédent',
    },
    next: {
        en: 'Next',
        it: 'Prossimo',
        de: 'Nächste',
        fr: 'Prochain',
    },
    // alerts
    alert_orderCreated: {
        en: 'Order created',
        it: 'Ordine creato con successo',
        de: 'Auftrag erstellt',
        fr: 'Ordre créé'
    },
    alert_addedToCart: {
        en: 'Product added to cart',
        it: 'Prodotto aggiunto al carrello',
        de: 'Produkt in den Warenkorb gelegt',
        fr: 'Produit ajouté au panier'
    },
    alert_cartDoEmpty: {
        en: 'Cart is now empty',
        it: 'Il carrello è vuoto',
        de: 'Der Warenkorb ist leer',
        fr: 'Le panier est vide'
    },
    alert_loginError: {
        en: 'Wrong login',
        it: 'Login errato',
        de: 'Falsches Login',
        fr: 'Mauvais login'
    },
    alert_loginSuccess: {
        en: 'You are Logged in',
        it: 'Accesso effettuato',
        de: 'Angemeldet',
        fr: 'Connecté'
    },
    alert_objectCreated: {
        en: 'Object created',
        it: 'Oggetto creato',
        de: 'Objekt erstellt',
        fr: 'Objet créé'
    },
    alert_objectUpdated: {
        en: 'Object updated',
        it: 'Oggetto aggiornato',
        de: 'Objekt aktualisiert',
        fr: 'Objet mis à jour'
    },
    active: {
        en: 'Active',
        it: 'Attivo',
        de: 'Aktiv',
        fr: 'Actif'
    },
    priceMultiplier: {
        en: 'Price multiplier',
        it: 'Moltiplicatore prezzo',
        de: 'Preismultiplikator',
        fr: 'Multiplicateur prix'
    },
    can_getProducts: {
        en: 'Can list products',
        it: 'Può listare i prodotti',
        de: 'Kann Produkte auflisten',
        fr: 'Peut répertorier les produits'
    },
    can_getProductsXls: {
        en: 'Can list products excel',
        it: 'Può listare i prodotti excel',
        de: 'Kann Produkte auflisten excel',
        fr: 'Peut répertorier les produits excel'
    },
    productsXlsLink: {
        en: 'Products Excel link',
        it: 'Pprodotti Excel URL',
        de: 'Produkte Excel URL',
        fr: 'Produits Excel URL'
    },
    can_getSingleProduct: {
        en: 'Can see single product',
        it: 'Può vedere un singolo prodotto',
        de: 'Kann einzelnes Produkt sehen',
        fr: 'Peut voir un seul produit'
    },
    can_getAvailability: {
        en: 'Can see availability',
        it: 'Può vedere la disponibilità',
        de: 'Verfügbarkeit sehen kann',
        fr: 'Peut voir la disponibilité'
    },
    can_setAvailability: {
        en: 'Can set availability',
        it: 'Può settare la disponibilità',
        de: 'Verfügbarkeit einstellen kann',
        fr: 'Peut définir la disponibilité'
    },
    can_readPricePerUnit: {
        en: 'Can read prices per unit',
        it: 'Può vedere i prezzi per unità',
        de: 'Kann Preis pro Einheit lesen',
        fr: 'Peut lire le prix unitaire'
    },
    can_readPricePerQm: {
        en: 'Can read prices per qm',
        it: 'Può vedere i prezzi per m²',
        de: 'Kann Preis pro m² lesen',
        fr: 'Peut lire le prix au m²'
    },
    product_notfound: {
        en: 'Product not found',
        it: 'Prodotto non trovato',
        de: 'Produkt nicht gefunden',
        fr: 'Produit non trouvé'
    },
    visibleProducts: {
        en: 'Visible products',
        it: 'Prodotti visibili',
        de: 'Sichtbare Produkte',
        fr: 'Produits visibles'
    },
    searchForNameOrErpId: {
        en: 'Search name or erp_id',
        it: 'Cerca nome o erp_id',
        de: 'Suchen name oder erp_id',
        fr: 'Rechercher le nom ou erp_id'
    },
    productsAction: {
        en: 'Products action',
        it: 'Azione prodotti',
        de: 'Aktion auf Produkte',
        fr: 'Action sur produits'
    },
    addToUser: {
        en: 'Add to user',
        it: 'Aggiungi all\'utente',
        de: 'Zum Benutzer hinzufügen',
        fr: 'Ajouter à l\'utilisateur'
    },
    roundPrices: {
        en: 'Round up prices',
        it: 'Arrotonda i prezzi',
        de: 'Runden Sie die Preise ab',
        fr: 'Arrondir les prix'
    },
    downloadAllProducts: {
        en: 'Download all products',
        it: 'Scarica tutti i prodotti',
        de: 'Laden Sie alle Produkte herunter',
        fr: 'Télécharger tous les produits'
    },
    deleteProduct: {
        en: 'Delete product',
        it: 'Elimina prodotto',
        de: 'Produkt löschen',
        fr: 'Supprimer le produit'
    },
    deleteOrder: {
        en: 'Delete order',
        it: 'Elimina ordine',
        de: 'Bestellung löschen',
        fr: 'Supprimer la commande'
    },
    deleteProducts: {
        en: 'Delete products',
        it: 'Elimina i prodotti',
        de: 'Produkte löschen',
        fr: 'Supprimer des produits'
    },
    deleteProduct_confirm: {
        en: 'Are you sure you want to delete the product/s?',
        it: 'Sei sicuro di volere cancellare il prodotto/i?',
        de: 'Möchten Sie das/die Produkt/e wirklich löschen?',
        fr: 'Êtes-vous sûr de vouloir supprimer le(s) produit(s)?'
    },
    deleteOrder_confirm: {
        en: 'Are you sure you want to delete the order?',
        it: 'Sei sicuro di volere cancellare l\'ordine?',
        de: 'Möchten Sie die Bestellung wirklich löschen?',
        fr: 'Êtes-vous sûr de vouloir supprimer la commande?'
    },
    exceptionUsers: {
        en: 'Exception Users products',
        it: 'Eccezione Utenti prodotti',
        de: 'Ausnahme Benutzerprodukte',
        fr: 'Exception Produits des utilisateurs'
    },
    exceptionNamesData: {
        en: 'Exception Category products',
        it: 'Prodotti di categoria eccezione',
        de: 'Produkte der Ausnahmekategorie',
        fr: 'Produits de la catégorie d\'exception'
    }

};


var localeMixin = {
    methods: {
        $trans: (string) =>
        {
            if (!window.localStorage.getItem('locale'))
            {
                window.localStorage.setItem('locale', 'de')
            }
            let lang = window.localStorage.getItem('locale');
            if (strings[string] === undefined)
            {
                return '[[' + string + ']]';
            }
            return strings[string][lang].length ? strings[string][lang] : ('[' + string + ']');
        }
    }
}

export default localeMixin;
