import Cart from './components/Cart'
import Latest from './components/Latest'
import List from './components/List'
import Search from './components/Search'
import ProductDetail from './components/products/Detail'
import Home from './components/Home'
import Login from './components/Login'
import PasswordLost from './components/passwordLost'
import Logout from './components/Logout'
import AdminUsers from './components/admin/AdminUsers'
import AdminProducts from './components/admin/AdminProducts'
import AdminOrders from './components/admin/AdminOrders'
import AdminSingleProduct from './components/admin/AdminSingleProduct'
import AdminSingleUser from './components/admin/AdminSingleUser'
import AdminNewUser from './components/admin/AdminNewUser'
import AdminSingleOrder from './components/admin/AdminSingleOrder'
import AdminPrices from './components/admin/AdminPrices'
// import AdminUpload from './components/admin/AdminUpload'

const routes = [
   { path: '/', component: Home, onlyAdmin: false },
   { path: '/login', component: Login, onlyAdmin: false },
   { path: '/password-lost', component: PasswordLost, onlyAdmin: false },
   { path: '/logout', component: Logout, onlyAdmin: false },
   { path: '/cart', component: Cart, onlyAdmin: false },
   { path: '/latest', component: Latest, onlyAdmin: false },
   { path: '/list', component: List, onlyAdmin: false },
   { path: '/search', component: Search, onlyAdmin: false },
   { path: '/user/:id', component: AdminSingleUser, onlyAdmin: false },
   { path: '/products/:id', component: ProductDetail },
   { path: '/admin-users', component: AdminUsers, onlyAdmin: true },
   { path: '/admin-users/new', component: AdminNewUser, onlyAdmin: true },
   { path: '/admin-users/:id', component: AdminSingleUser, onlyAdmin: true },
   { path: '/admin-products', component: AdminProducts, onlyAdmin: true },
   { path: '/admin-products/:id', component: AdminSingleProduct, onlyAdmin: true },
   { path: '/admin-orders', component: AdminOrders, onlyAdmin: true },
   { path: '/admin-name-data', component: AdminPrices, onlyAdmin: true },
   // { path: '/admin-upload', component: AdminUpload, onlyAdmin: true },
   { path: '/admin-orders/:id', component: AdminSingleOrder, onlyAdmin: true },
]

export default routes;