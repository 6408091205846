
<template>
   <div class="container">
      <div class="row">
         <div class="col-md-6">
            <adminPaginator :pagination="pagination" @gotopage="goToPage"></adminPaginator>
         </div>
         <div class="col-md-6" v-if="$root.selectedProducts.length > 0">
            <div class="row">
               <div class="col-md-6">
                  <searcher @get-data="getData" />
               </div>
               <div class="col-md-6">
                  <select class="form-control" @change="selectedAction($event)" ref="selectedActionRef">
                     <option value="" selected disabled>{{ $trans('productsAction', $root.user.locale) }}</option>
                     <option value="add-to-cart">{{ $trans('addToCart', $root.user.locale) }}</option>
                     <option value="export-xlsx">Export Excel</option>
                     <option value="bulk-delete">{{ $trans('deleteProducts', $root.user.locale) }}</option>
                     <option value="_" disabled></option>
                     <option v-for="user in usersWithApi" :key="user.id" :value="user.id">
                        => API: {{ user.id_name }}
                     </option>
                  </select>
               </div>
            </div>
         </div>
         <div class="col-md-6" v-else>
            <searcher @get-data="getData" />
         </div>
      </div>
      <div class="table-responsive">
         <vuetable ref="vuetable"
         :api-mode="false"
         :fields="fields"
         @vuetable:checkbox-toggled-all="toggleSelectAll"
         @vuetable:row-clicked="clicked"
         :multi-sort="false"
         :per-page="22"
         :data-manager="dataManager"
         :sort-order="[{
            sortField: 'default',
            direction: ''
         }]"
         ></vuetable>
      </div>
      <adminPaginator :pagination="pagination" @gotopage="goToPage"></adminPaginator>
      <br>
      <button class="btn btn-warning btn-sm" @click="exportAll"><i class="fa fa-download"></i> {{ $trans('downloadAllProducts', $root.user.locale) }}</button>
   </div>
</template>
<script>
import Vuetable from 'vuetable-2'
import AdminPaginator from './AdminPaginator'
import Searcher from './../Searcher'
import Vue from 'vue'
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue'
Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox)
export default {
   components: { Vuetable, AdminPaginator, Searcher },
   mounted() {
      if (this.$route.query.page) {
         this.page = this.$route.query.page
      } else {
         this.$router.replace({query: {page: 1, orderby:'erp_id-desc'}}).catch(()=>{})
      }
      this.getData()
   },
   beforeRouteEnter(to, from, next) {
      next(() => {
         if (from.path !== '/' && !from.path.includes('/admin-products')) {
            window.vm.resetTempData()
         }
      });
   },
   data() {
      return {
         pagination: {},
         usersWithApi: [],
         page: 1,
         products: [],
         fields: [
            {
               name: 'img',
               title: 'Img',
               sortField: 'img',
               formatter: (value) => {
                  return '<div style="background-image:url('+value+'); background-size:contain; background-repeat:no-repeat; background-position:left; width:50px; height:60px;" />'
               }
            },
            {
               name: 'vuetable-field-checkbox',
               title: "checkbox",
            },
            {
               name: 'id',
               title: 'ID',
               sortField: 'id'
            },
            {
               name: 'erp_id',
               title: 'ERP ID',
               sortField: 'erp_id'
            },
            {
               name: 'name',
               title: this.$trans('name', this.$root.user.locale),
               sortField: 'name'
            },
            {
               name: 'origin',
               title: this.$trans('origin', this.$root.user.locale),
               sortField: 'origin'
            },
            {
               name: 'color',
               title: this.$trans('color', this.$root.user.locale),
               sortField: 'color'
            },
            {
               name: 'qm',
               title: this.$trans('square_footage', this.$root.user.locale),
               sortField: 'qm'
            },
            {
               name: 'material',
               title: this.$trans('material', this.$root.user.locale),
               sortField: 'material'
            },
            {
               name: 'knots',
               title: this.$trans('knots', this.$root.user.locale),
               sortField: 'knots'
            },
            {
               name: 'price_per_qm',
               title: this.$trans('price_per_qm', this.$root.user.locale),
               sortField: 'price_per_qm'
            },
            {
               name: 'price_per_unit',
               title: this.$trans('price_per_unit', this.$root.user.locale),
               sortField: 'price_per_unit'
            },
            {
               name: 'status',
               title: this.$trans('available', this.$root.user.locale),
               sortField: 'status',
               formatter : (val) => val === 'available' ? '✓' : '-'
            },
         ]
      }
   },
   methods: {
      getData() {
         window.axios.get('/products', {
            params: {
               page: this.$route.query.page,
               string: this.$route.query.search ?? null,
               orderby: this.$route.query.orderby ?? 'erp_id-desc'
            },
            headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
         })
         .then(res => {
            this.products = res.data.data
            this.pagination = this.$root.getPagination(res.data)
            if (this.$refs.vuetable !== undefined) {
               this.$refs.vuetable.reload()
               this.$refs.vuetable.selectedTo = this.$root.selectedProducts;
            }
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})

         window.axios.get('/users-with-api', {
            headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
         })
         .then(res => {
            this.usersWithApi = res.data
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      },
      goToPage(page) {
         this.$router.replace({query:{page:page, orderby:this.$route.query.orderby, search: this.$route.query.search}}).catch(()=>{})
         // this.getData()
      },
      clicked(data) {
         console.log(data.event)
         if (!data.event.target.classList.contains('vuetable-td-component-checkbox') && data.event.target.type != 'checkbox') {
            this.$router.push('/admin-products/'+data.data.id);
            return;
         }
         if (data.event.target.type == 'checkbox') {
            if (!this.$root.selectedProducts.includes(data.data.id))
               this.$root.selectedProducts.push(data.data.id);
            else
               this.$root.selectedProducts = this.$root.selectedProducts.filter(o => { return o != data.data.id });
         }
      },
      dataManager(sortOrder, pagination) {
         if (sortOrder.length > 0) { 
            let newsortorder = sortOrder[0].sortField+'-'+sortOrder[0].direction
            if (newsortorder !== 'default-' && newsortorder !== this.$route.query.orderby ) {
               let newsortorder = sortOrder[0].sortField+'-'+sortOrder[0].direction
               this.$router.replace({query:{page:this.$route.query.page, orderby: newsortorder}}) ;
            }
         }
         return {
            pagination: pagination,
            data: this.products
         };
      },
      toggleSelectAll(selected) {
         this.$root.toggleSelectAllProducts(selected, this.products)
      },
      selectedAction(event) {
         const val = event.target.value;
         if (val && val === 'add-to-cart') {
            this.$root.addToCartSelected();
         } else if (val && val === 'bulk-delete') {
            if (confirm(this.$trans('delete_confirm', this.$root.user.locale))) {
               this.$root.bulkDeleteSelected();
            }
         } else if (val && val === 'export-xlsx') {
            this.$root.exportSelectedProductsXLSX();
         } else if (typeof val === 'number' || typeof val === 'string') {
            this.$root.addProductsToApi(val)
         }
         this.$refs['selectedActionRef'].value = "";
         this.$refs.vuetable.selectedTo = []
      },
      exportAll() {
         this.$root.exportAllProductsXLSX();
      }
   }
}
</script>
