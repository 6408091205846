<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="table">
                    <h5>{{ $trans('registry', $root.user.locale) }}</h5>
                    <table class="table table-borderless table-sm">
                        <tr>
                            <td style="width: 30%">ERP_ID</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.erp_id" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('email', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="email" v-model="user.email" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('userName', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.name" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('password', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.password" class="form-control" :placeholder=" $trans('compileToChange', $root.user.locale)"/>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table class="table table-borderless table-sm">
                        <tr>
                            <td style="width: 50%">{{ $trans('priceMultiplier', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="number" step="0.01" v-model="user.price_multiplier" class="form-control">
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table class="table table-borderless table-sm">
                        <tr>
                            <td style="width: 30%">{{ $trans('companyname', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.companyname" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('telephone', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.tel" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('fax', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.fax" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('mobile', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.mobile" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('website', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.website" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('country', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <select v-model="user.countryCode" class="form-control">
                                        <option v-for="(country,code) in countries" :key="code" :value="code" v-text="country"/>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('postcode', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.postcode" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('address', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.address" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('city', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="text" v-model="user.city" class="form-control"/>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <br>
                    <button class="btn btn-success" @click="save">{{ $trans('save', $root.user.locale) }}</button>
                </div>
            </div>
            <div class="col-md-6">

                <div class="table">
                    <h5>Logo</h5>
                    <table class="table table-borderless table-sm">
                        <tr>
                            <td></td>
                            <td><img v-if="user.logo" :src="logosurl + '/'+ user.logo" style="max-width:100px; max-height:100px;"/></td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('newLogo', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="chagedLogo"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 30%">{{ $trans('removeLogo', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="removeLogo">
                                </div>
                            </td>
                        </tr>
                    </table>
                    <button class="btn btn-success" @click="sendUploadLogo">{{ $trans('save', $root.user.locale) }} Logo</button>
                </div>

                <hr>
                <div class="table" v-show="$root.user.admin">
                    <h5>API {{ $trans('integration', $root.user.locale) }}</h5>
                    <table class="table table-borderless table-sm">
                        <tr>
                            <td style="width: 80%">{{ $trans('active', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.enabled">
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80%">Init</td>
                            <td>
                                <div class="form-group">
                                    <select class="form-control" v-model="api.request_from">
                                        <option value="client">Client</option>
                                        <option value="server">Server</option>
                                        <option value="shopify-kelim">Server to Kelim Shopify</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.request_from === 'server'">
                            <td colspan="2">
                                <div class="form-group">
                                    Client Endpoint:
                                    <input type="text" class="form-control" v-model="api.client_endpoint"/>
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td colspan="2">
                                <div class="form-group">
                                    API KEY:
                                    <input type="text" v-model="api.api_key" class="form-control"/>
                                </div>
                            </td>
                        </tr>


                        <tr v-show="api.enabled">
                            <td colspan="2">{{ $trans('exceptionUsers', $root.user.locale) }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="form-group">
                                    <multiselect v-model="api.exception_users"
                                                 :options="allUsers"
                                                 :multiple="true"
                                                 :close-on-select="true"
                                                 :clear-on-select="false"
                                                 return="id"
                                                 :preserve-search="true"
                                                 placeholder="Pick some"
                                                 label="email"
                                                 track-by="id"
                                                 :preselect-first="false">
                                        <template slot="selection" slot-scope="{ values, isOpen }">
                                            <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                                {{
                                                    values.map(o =>
                                                    {
                                                        return o.email
                                                    }).join(', ')
                                                }}
                                            </span>
                                        </template>
                                    </multiselect>

                                </div>
                            </td>
                        </tr>

                        <tr v-show="api.enabled">
                            <td colspan="2">{{ $trans('exceptionNamesData', $root.user.locale) }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="form-group">
                                    <multiselect v-model="api.exception_names_data"
                                                 :options="allNamesData"
                                                 :multiple="true"
                                                 :close-on-select="true"
                                                 :clear-on-select="false"
                                                 return="id"
                                                 :preserve-search="true"
                                                 placeholder="Pick some"
                                                 label="name"
                                                 track-by="id"
                                                 :preselect-first="false">
                                        <template slot="selection" slot-scope="{ values, isOpen }">
                                            <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                                {{
                                                    values.map(o =>
                                                    {
                                                        return o.name
                                                    }).join(', ')
                                                }}
                                            </span>
                                        </template>
                                    </multiselect>

                                </div>
                            </td>
                        </tr>

                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('roundPrices', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.round_prices">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('can_getProducts', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.get_products">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('can_getProductsXls', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.get_products_xls">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled && api.get_products_xls">
                            <td colspan="2">
                                <div class="form-group">
                                    <label style="width: 100%;">{{ $trans('productsXlsLink', $root.user.locale) }} :</label>
                                    <input style="width: 100%;" type="text" v-model="ProductsXlsLink">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('can_getSingleProduct', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.get_single_product">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('can_getAvailability', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.get_availability">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('can_setAvailability', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.set_availability">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('can_readPricePerUnit', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.read_price_per_unit">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td style="width: 80%">{{ $trans('can_readPricePerQm', $root.user.locale) }}</td>
                            <td>
                                <div class="form-group">
                                    <input type="checkbox" v-model="api.read_price_per_qm">
                                </div>
                            </td>
                        </tr>
                        <tr v-show="api.enabled">
                            <td colspan="2">
                                <br>
                                <h6>{{ $trans('visibleProducts', $root.user.locale) }}:</h6>
                                <br>
                                <table class="table table-borderless table-sm">
                                    <tr>
                                        <td style="width: 80%">{{ $trans('enableAllProducts', $root.user.locale) }}</td>
                                        <td>
                                            <div class="form-group">
                                                <input type="checkbox" v-model="api.all_products">
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <br>
                                Upload Excel: <input ref="uploadXLSX"
                                                     type="file"
                                                     name="file-upload"
                                                     @change="onUploadXLSX($event)"/>
                                <br>
                                <i><u @click="downloadXLSX" style="cursor:pointer;">Download Excel</u></i>
                                <br>
                                <br>
                                <div class="table table-responsive" style="margin-bottom: 0px;">
                                    <table class="table table-striped table-condensed" style="margin-bottom: 0px;">
                                        <tr>
                                            <td style="width:20px;">
                                                <input type="checkbox" v-model="checkAll"/>
                                            </td>
                                            <!-- <td style="width:20px;">
                                            </td> -->
                                            <td>
                                                <input type="text" v-model="productNameFilter" class="form-control" :placeholder="$trans('searchForNameOrErpId', $root.user.locale)"/>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="table table-responsive" style="height: 400px; overflow-y:auto;">
                                    <table class="table table-striped table-condensed">
                                        <tr v-for="prod in allProductsFiltered.sort((a, b) => { return integrationProducts.includes(b.id) - integrationProducts.includes(a.id); })" :key="prod.erp_id">
                                            <td style="width:20px;">
                                                <input type="checkbox" :checked="integrationProducts.includes(prod.id)" @change="checkProd(prod.id)"/>
                                            </td>
                                            <td style="width:20px;" v-text="prod.erp_id"></td>
                                            <td style="width:20px;" v-text="prod.status == 'available' ? '✓' : ''"></td>
                                            <!-- <td style="width:20px;">
                                               <img style="width:auto; height:auto; max-height:20px; max-width:20px; padding:0px;" :src="prod.img">
                                            </td> -->
                                            <td v-text="`${prod.name} ${prod.length}x${prod.width}`"></td>
                                        </tr>
                                    </table>
                                </div>
                                <br>
                                Tot {{ integrationProducts.length }}
                            </td>
                        </tr>
                    </table>
                    <br>
                    <button class="btn btn-success" @click="saveApi">{{ $trans('save', $root.user.locale) }}</button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import countries from './../../countryCodes.js'
import Multiselect from 'vue-multiselect'

export default {
    components: {Multiselect},
    mounted()
    {
        window.axios.get('/users/' + this.$route.params.id,
            {headers: {Authentication: `bearer ${window.localStorage.getItem('token')}`}})
              .then(res =>
              {
                  this.user = res.data.user;
                  this.allProducts = res.data.all_products;
                  this.allNamesData = res.data.all_names_data;
                  this.allUsers = res.data.all_users;
                  this.integrationProducts = res.data.integration_products;
                  if (res.data.api && this.$route.params.id !== 'current')
                  {
                      this.api = res.data.api
                  }
              })
              .catch(err =>
              {
                  window.$.notify({message: err}, {type: 'danger', delay: 2000});
              })
    },
    data()
    {
        return {
            checkAll: false,
            productNameFilter: "",
            user: {},
            api: {
                api_key: null,
                enabled: false,
                round_prices: false,
                request_from: 'client',
                client_endpoint: '',
                price_multiplier: 1.00,
                get_products: false,
                get_products_xls: false,
                get_single_product: false,
                get_availability: false,
                set_availability: false,
                read_price_per_unit: false,
                read_price_per_qm: false,
                all_products: false,
                exception_users:[],
                exception_names_data:[]
            },
            countries: countries,
            logo: null,
            removeLogo: false,
            logosurl: process.env.VUE_APP_LOGOS_BASE_URL,
            allUsers: [],
            allNamesData: [],
            allProducts: [],
            integrationProducts: {},
        }
    },
    watch: {
        // '$root.user'(newUser) {
        //    this.user = newUser
        // }
        checkAll(val)
        {
            this.integrationProducts = val
                ? Object.values(this.allProducts.map(o => o.id))
                : [];
        }
    },
    computed: {
        allProductsFiltered()
        {
            return Object.values(this.allProducts).filter(o =>
            {
                if (this.productNameFilter.trim().length == 0)
                {
                    return true;
                }
                return String(o.erp_id).toLowerCase().includes(this.productNameFilter.trim().toLowerCase())
                    || String(o.name).toLowerCase().includes(this.productNameFilter.trim().toLowerCase());
            })
        },
        ProductsXlsLink()
        {
            return process.env.VUE_APP_AXIOS_BASE_URL + '/v1/' + this.api.api_key + '/productsXls';
        }
    },
    methods: {
        checkProd(productId)
        {
            if (this.integrationProducts.includes(productId))
            {
                this.integrationProducts = this.integrationProducts.filter(o =>
                {
                    return o != productId;
                })
            }
            else
            {
                this.integrationProducts.push(productId);
            }
        },
        save()
        {
            window.axios.post('/users/' + this.$route.params.id,
                this.user,
                {headers: {Authentication: `bearer ${window.localStorage.getItem('token')}`}})
                  .then(res =>
                  {
                      this.user = res.data
                      window.$.notify({message: this.$trans('alert_objectUpdated', this.$root.user.locale)}, {type: 'success', delay: 2000});
                  })
                  .catch(err =>
                  {
                      window.$.notify({message: err}, {type: 'danger', delay: 2000});
                  })
        },
        saveApi()
        {
            window.axios.post('/users/' + this.$route.params.id + '/update-api',
                {api: this.api, integrationProducts: this.integrationProducts},
                {headers: {Authentication: `bearer ${window.localStorage.getItem('token')}`}})
                  .then(res =>
                  {
                      this.api = res.data
                      window.$.notify({message: this.$trans('alert_objectUpdated', this.$root.user.locale)}, {type: 'success', delay: 2000});
                  })
                  .catch(err =>
                  {
                      window.$.notify({message: err}, {type: 'danger', delay: 2000});
                  })
        },
        chagedLogo(e)
        {
            this.file = e.target.files[0];
        },
        sendUploadLogo()
        {
            let formData = new FormData();
            formData.append("logo", this.file);
            formData.append("removeLogo", this.removeLogo);
            window.axios.post("/users/" + this.$route.params.id + '/update-logo', formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                        Authentication: `bearer ${window.localStorage.getItem('token')}`
                    }
                })
                  .then(res =>
                  {
                      this.user = res.data;
                      this.logo = null;
                      location.reload();
                  })
        },
        onUploadXLSX(e)
        {
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length)
            {
                return;
            }
            const formData = new FormData();
            formData.append("xlsx", files[0]);
            window.axios.post("/users/" + this.$route.params.id + '/upload-api-xlsx', formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                        Authentication: `bearer ${window.localStorage.getItem('token')}`
                    }
                })
                  .then(res =>
                  {
                      this.api = res.data
                      location.reload();
                  })
        },
        downloadXLSX()
        {
            window.open(process.env.VUE_APP_AXIOS_BASE_URL + '/products-xlsx?tk=' + window.localStorage.getItem('token') + '&user_id=' + this.user.id + '&product_ids=' + this.integrationProducts.join('x'));
        }
    }
}
</script>
